<template>
    <div class="reward-level">
        <div class="reward-level-inner">
            <div class="reward-level-progress">
                <div class="level-bar" style="width: 36px;" />
                <div class="level-bar" :style="{ width: levelWidth }"
                     v-if="levelLength === 1"
                />
                <template v-if="levelLength > 1">
                    <div class="level-bar" :style="{ width: levelWidth }"
                         v-for="n in levelLength - 1" :key="n"
                    >
                        <div class="level-point">
                            <p class="ordernum">{{ levels[n-1].orderNumThreshold }}{{ type | formatLevelUnit }}</p>
                            <div class="hold"
                                 @mouseenter="changeLevelRewardVisible(n-1, true)"
                                 @mouseleave="changeLevelRewardVisible(n-1, false)"
                            >
                                <span v-if="type === 1 || type === 4 || type === 5 ||
                                    type === 'TMALL_ORDER_NUM_REWARD' || type === 'TMALL_GMV_REWARD' || 
                                    type === 'TMALL_GMV_RATE' || type === 'TMALL_FLOW_USR_CPA' ||
                                    type === 'TAOBAO_ORDER_NUM_REWARD' || type === 'TAOBAO_GMV_REWARD' || 
                                    type === 'TAOBAO_GMV_RATE' || type === 'TAOBAO_RED_ORDER_NUM_REWARD'" 
                                >
                                    <img v-if="myOrderNum >= levels[n-1].orderNumThreshold"
                                         src="https://kaola-haitao.oss.kaolacdn.com/a78b4577-9668-4d14-8480-e02c815640c0_64_64.png" alt=""
                                    >
                                    <img v-else src="https://kaola-haitao.oss.kaolacdn.com/655a6a5f-d34a-42d2-aff0-c67327f71010_64_64.png">
                                </span>
                                <span v-if="type === 2 ||
                                    type === 3 ||
                                    type === 'TMALL_ZHUANKE_INVITE_NEW'||
                                    type === 'TAOBAO_ZHUANKE_INVITE_NEW'"
                                >
                                    <img v-if="myOrderNum >= levels[n-1].orderNumThreshold"
                                         src="https://img.alicdn.com/tfs/TB1MUGMu4D1gK0jSZFKXXcJrVXa-30-30.png" alt=""
                                    >
                                    <img v-else src="https://img.alicdn.com/tfs/TB1iEKHuVT7gK0jSZFpXXaTkpXa-30-30.png">
                                </span>
                                <div class="hold-text"
                                     :style="{ opacity: !!levelRewardVisible[n-1] ? 1 : 0 }"
                                >
                                    <div v-if="type === 1 || type === 'TMALL_ORDER_NUM_REWARD' || type === 'TAOBAO_ORDER_NUM_REWARD' || type === 'TAOBAO_RED_ORDER_NUM_REWARD'">每单奖励<span>{{ levels[n-1].rewardAmount }}</span>元</div>
                                    <div v-if="type === 2 || type === 'TMALL_ZHUANKE_INVITE_NEW' || type === 'TAOBAO_ZHUANKE_INVITE_NEW'">每个奖励<span>{{ levels[n-1].rewardAmount }}</span>元</div>
                                    <div v-if="type === 3">每张奖励<span>{{ levels[n-1].rewardAmount }}</span>元</div>
                                    <div v-if="type === 4 || type === 'TMALL_GMV_REWARD'">奖励<span>{{ levels[n-1].rewardAmount }}</span>元</div>
                                    <div v-if="type === 5 || type === 'TMALL_GMV_RATE'">奖励<span>{{ levels[n-1].rewardAmount }}</span>%</div>
                                    <div v-if="type === 'TMALL_FLOW_USR_CPA'">每人奖励<span>{{ levels[n-1].rewardAmount }}</span>元</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="level-bar" style="width: 36px;">
                    <div class="level-point last">
                        <p class="ordernum">{{ levels[levels.length-1].orderNumThreshold }}{{ type | formatLevelUnit }}</p>
                        <div class="hold"
                             @mouseenter="changeLevelRewardVisible(levelRewardVisible.length-1, true)"
                             @mouseleave="changeLevelRewardVisible(levelRewardVisible.length-1, false)"
                        >
                            <div class="hold-text"
                                 :style="{ opacity: !!levelRewardVisible[levelRewardVisible.length-1] ? 1 : 0 }"
                            >
                                <p v-if="type === 1 || type === 'TMALL_ORDER_NUM_REWARD' || type === 'TAOBAO_ORDER_NUM_REWARD' || type === 'TAOBAO_RED_ORDER_NUM_REWARD'">每单奖励<span>{{ levels[levels.length-1].rewardAmount }}</span>元</p>
                                <p v-if="type === 2 || type === 'TMALL_ZHUANKE_INVITE_NEW' || type === 'TAOBAO_ZHUANKE_INVITE_NEW'">每个奖励<span>{{ levels[levels.length-1].rewardAmount }}</span>元</p>
                                <p v-if="type === 3">每张奖励<span>{{ levels[levels.length-1].rewardAmount }}</span>元</p>
                                <p v-if="type === 'TMALL_FLOW_USR_CPA'">每人奖励<span>{{ levels[levels.length-1].rewardAmount }}</span>元</p>
                                <p v-if="type === 1 || type === 2 || type === 3 || 
                                    type === 'TMALL_ORDER_NUM_REWARD' || 
                                    type === 'TMALL_ZHUANKE_INVITE_NEW' ||
                                    type === 'TMALL_FLOW_USR_CPA' ||
                                    type === 'TAOBAO_ORDER_NUM_REWARD' || 
                                    type === 'TAOBAO_ZHUANKE_INVITE_NEW'"
                                >
                                    最高奖励<span>{{ rewardLimit }}</span>元
                                </p>
                                <p v-if="type === 4 || type === 'TMALL_GMV_REWARD' || type === 'TAOBAO_GMV_REWARD'">奖励<span>{{ levels[levels.length-1].rewardAmount }}</span>元</p>
                                <p v-if="type === 5 || type === 'TMALL_GMV_RATE' || type === 'TAOBAO_GMV_RATE'">奖励<span>{{ levels[levels.length-1].rewardAmount }}</span>%</p>
                            </div>
                            <span v-if="type === 1 || type === 4 || type === 5 ||
                                type === 'TMALL_ORDER_NUM_REWARD' || type === 'TMALL_GMV_REWARD' || 
                                type === 'TMALL_GMV_RATE' || type === 'TMALL_FLOW_USR_CPA' ||
                                type === 'TAOBAO_ORDER_NUM_REWARD' || type === 'TAOBAO_GMV_REWARD' || 
                                type === 'TAOBAO_GMV_RATE' || type === 'TAOBAO_RED_ORDER_NUM_REWARD'"
                            >
                                <img v-if="myOrderNum >= levels[levels.length-1].orderNumThreshold"
                                     src="//kaola-haitao.oss.kaolacdn.com/a78b4577-9668-4d14-8480-e02c815640c0_64_64.png" alt=""
                                >
                                <img v-else
                                     src="https://kaola-haitao.oss.kaolacdn.com/655a6a5f-d34a-42d2-aff0-c67327f71010_64_64.png" alt=""
                                >
                            </span>
                            <span v-if="type === 2 || type === 3 || 
                                type === 'TMALL_ZHUANKE_INVITE_NEW' ||
                                type === 'TAOBAO_ZHUANKE_INVITE_NEW'" 
                            >
                                <img v-if="myOrderNum >= levels[levels.length-1].orderNumThreshold"
                                     src="https://img.alicdn.com/tfs/TB1MUGMu4D1gK0jSZFKXXcJrVXa-30-30.png" alt=""
                                >
                                <img v-else
                                     src="https://img.alicdn.com/tfs/TB1iEKHuVT7gK0jSZFpXXaTkpXa-30-30.png" alt=""
                                >
                            </span>
                        </div>
                    </div>
                </div>

                <div class="reward-level-progress-copy">
                    <div class="level-bar" style="width: 36px;">
                        <div class="level-bar-inner"
                             :style="activeLevelWidth[0]"
                        >
                            <p v-show="activeLevelWidth[0].showMyOrderNum">{{ myOrderNum }}</p>
                        </div>
                    </div>
                    <div class="level-bar" :style="{ width: levelWidth }"
                         v-if="levelLength === 1"
                    >
                        <div class="level-bar-inner"
                             :style="activeLevelWidth[1]"
                        >
                            <p v-show="activeLevelWidth[1].showMyOrderNum">{{ myOrderNum }}</p>
                        </div>
                    </div>
                    <template v-if="levelLength > 1">
                        <div class="level-bar" :style="{ width: levelWidth }"
                             v-for="n in levelLength - 1" :key="n"
                        >
                            <div class="level-bar-inner"
                                 :style="activeLevelWidth[n]"
                            >
                                <p v-show="activeLevelWidth[n].showMyOrderNum">{{ myOrderNum }}</p>
                            </div>
                        </div>
                    </template>
                    <div class="level-bar" style="width: 36px;">
                        <div class="level-bar-inner"
                             :style="activeLevelWidth[activeLevelWidth.length - 1]"
                        >
                            <p v-show="activeLevelWidth[activeLevelWidth.length - 1].showMyOrderNum">{{ myOrderNum }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['levels', 'myOrderNum', 'rewardLimit', 'type'],

    data() {
        return {
            levelRewardVisible: [],
            mouseMoveChangeRewardVisibleFlag: false
        };
    },

    filters: {
        formatLevelUnit(type) {
            switch (type) {
            case 3:
                return '单';
            case 4: 
            case 5:
            case 'TMALL_GMV_RATE':
            case 'TMALL_GMV_REWARD':
            case 'TAOBAO_GMV_RATE':
            case 'TAOBAO_GMV_REWARD':
                return '元';
            case 'TMALL_FLOW_USR_CPA':
                return '';
            default:
                return '笔';
            }
        }
    },

    methods: {
        initLevelRewardVisible() {
            this.mouseMoveChangeRewardVisibleFlag = false;
            this.levelRewardVisible = this.levels.map(() => true);
            setTimeout(() => {
                this.levelRewardVisible = this.levels.map(() => false);
                this.mouseMoveChangeRewardVisibleFlag = true;
            }, 3000);
        },

        changeLevelRewardVisible(index, show) {
            if(!this.mouseMoveChangeRewardVisibleFlag) {
                return;
            }
            this.levelRewardVisible.splice(index, 1, show);
        }
    },

    watch: {
        levels: {
            handler() {
                this.initLevelRewardVisible();
            },
            immediate: true
        }
    },

    computed: {
        levelLength() {
            return this.levels.length;
        },

        levelWidth() {
            if(this.levelLength === 1) {
                return '100%';
            }
            return `${1 / (this.levelLength - 1) * 100}%`;
        },

        /* eslint-disable */
        activeLevelWidth() {
            let arr = [];
            if(this.levelLength === 1) {
                arr.push({
                    width: '100%',
                    borderTopLeftRadius: '14px',
                    borderBottomLeftRadius: '14px'
                });
                if(this.myOrderNum > this.levels[0].orderNumThreshold) {
                    arr.push({
                        width: '100%'
                    });
                    arr.push({
                        width: '100%',
                        borderTopRightRadius: '14px',
                        borderBottomRightRadius: '14px',
                        backgroundImage: 'linear-gradient(90deg, #FF0000 0%, #FF3264 100%)',
                        showMyOrderNum: true
                    });
                } else {
                    arr.push({
                        width: `${this.myOrderNum / this.levels[0].orderNumThreshold * 100}%`,
                        borderTopRightRadius: '14px',
                        borderBottomRightRadius: '14px',
                        backgroundImage: 'linear-gradient(90deg, #FF0000 0%, #FF3264 100%)',
                        showMyOrderNum: true
                    });
                    arr.push({
                        width: 0
                    })
                }
                return arr;
            }

            if(this.myOrderNum < this.levels[0].orderNumThreshold) {
                let w = '24px';
                if(this.myOrderNum / this.levels[0].orderNumThreshold > 0.55) {
                    w = `${this.myOrderNum / this.levels[0].orderNumThreshold * 100}%`;
                }
                return [{
                    width: w,
                    borderRadius: '14px',
                    backgroundImage: 'linear-gradient(90deg, #FF0000 0%, #FF3264 100%)',
                    showMyOrderNum: true
                }, ...this.levels.map(() => ({
                    width: 0
                }))];
            }
            if(this.myOrderNum >= this.levels[this.levels.length - 1].orderNumThreshold) {
                arr = [{
                    width: '100%',
                    borderTopLeftRadius: '14px',
                    borderBottomLeftRadius: '14px'
                }, ...this.levels.map(() => ({
                    width: '100%'
                }))];
                arr[arr.length - 1].borderTopRightRadius = '14px';
                arr[arr.length - 1].borderBottomRightRadius = '14px';
                arr[arr.length - 1].backgroundImage = 'linear-gradient(90deg, #FF0000 0%, #FF3264 100%)';
                arr[arr.length - 1].showMyOrderNum = true;
                return arr;
            }
            arr.push({
                width: '100%',
                borderTopLeftRadius: '14px',
                borderBottomLeftRadius: '14px'
            });
            for(let i = 0; i < this.levels.length - 1; i++) {
                let curOrderNum = this.levels[i].orderNumThreshold;
                let nextOrderNum = this.levels[i + 1].orderNumThreshold;
                if(this.myOrderNum < curOrderNum) {
                    arr.push({
                        width: 0
                    });
                } else if(this.myOrderNum >=curOrderNum && this.myOrderNum <= nextOrderNum) {
                    arr.push({
                        width: `${(this.myOrderNum - curOrderNum) / (nextOrderNum - curOrderNum) * 100}%`,
                        borderTopRightRadius: '14px',
                        borderBottomRightRadius: '14px',
                        backgroundImage: 'linear-gradient(90deg, #FF0000 0%, #FF3264 100%)',
                        showMyOrderNum: true
                    });
                } else {
                    arr.push({
                        width: '100%'
                    });
                }
            }
            arr.push({
                width: 0
            });
            return arr;
        }
        /* eslint-enable */
    }
};
</script>

<style lang="scss" scoped>
.reward-level {
    position: relative;
    height: 180px;
    background: #f7f7f7;
    border-radius: 4px;

    &-inner {
        position: relative;
        padding: 104px 32px 0;
    }
    &-progress {
        position: relative;
        height: 18px;
        background: rgba(189,189,189,.3);
        border-radius: 14px;
        display: flex;

        .level-bar {
            position: relative;

            .level-point {
                position: absolute;
                left: 0;
                top: 3px;
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: rgba(255,255,255,.7);
                z-index: 2;
                &.last {
                    left: -14px;

                    .hold {
                        left: auto;
                        right: -8px;
                    }
                }
                .ordernum {
                    position: absolute;
                    left: 50%;
                    bottom: -30px;
                    width: 90px;
                    text-align: center;
                    transform: translate3d(-50%,0,0);
                    color: #333;
                    font-size: 14px;
                }
                .hold {
                    position: absolute;
                    left: -8px;
                    top: -50px;
                    white-space: nowrap;
                    img { width: 30px; height: 30px; vertical-align: middle; cursor: pointer; }
                    &-text {
                        margin-left: 4px;
                        display: inline-block;
                        font-size: 14px;
                        vertical-align: middle;
                        color: #333;
                        transition: all .5s;
                        span { color: #ff0000; }
                    }
                }
            }
        }
    }
    &-progress-copy {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        display: flex;
        height: 18px;
        border-radius: 14px;
    }

    .level-bar {
        height: 18px;

        &-inner {
            position: relative;
            width: 0;
            height: 18px;
            background: #ff0000;

            p {
                position: absolute;
                right: 10px;
                color: #fff;
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}
</style>
